@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;500;600;700&display=swap");

:root {
  --darkgreen: #20513b;
  --darkred: #9e3120;
  --palegreen: #ecf2f0;
  --palegreen70: rgba(236, 242, 240, 0.7);
  --black20: #15151533;
  --palered: #fdd0cd;
  --white: #ffffff;
  --black: #151515;
}

.green {
  color: var(--darkgreen);
}

.black20 {
  color: var(--black20);
}

h1 {
  font-family: "Outfit";
  font-weight: 500;
  font-size: 48px;
  color: var(--black);
  margin: 0px;
}

h2 {
  font-family: "Outfit";
  font-weight: 500;
  font-size: 32px;
  color: var(--black);
  margin: 0px;
}

h3 {
  font-family: "Outfit";
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  line-height: 20px;
}

h4 {
  font-family: "Outfit";
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

h5 {
  font-family: "Outfit";
  font-weight: 500;
  font-size: 12px;
  margin: 0;
}

p {
  font-family: "Outfit";
  font-weight: 300;
  font-size: 14px;
  color: var(--black);
  margin: 0px;
}

a {
  font-family: "Outfit";
  font-weight: 300;
  text-decoration: none;
  font-size: 14px;
  color: var(--black);
  margin: 0px;
  cursor: pointer;
}

a:hover {
  color: var(--darkgreen);
}

b {
  font-weight: 700;
}

ul {
  margin: 0px;
  text-align: left;
}

li {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

li:last-of-type {
  margin-bottom: 0px;
}

table {
  width: 100%;
  margin-left: -3px;
}

td {
  font-family: "Outfit";
  font-weight: 300;
  font-size: 14px;
  color: var(--black);
  margin: 0px;
  text-align: center;
}

td:first-of-type {
  text-align: left;
}

input {
  height: 56px;
  width: 100%;
  background: var(--palegreen);
  border: 1px solid var(--palegreen);
  border-radius: 8px;
  font-family: "Outfit";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 16px 0px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input:hover,
input:active,
input:focus {
  border: 1px solid var(--black20);
  outline: none;
}

input:disabled {
  opacity: 0.5;
}

.back-button {
  cursor: pointer;
}

.halfinput {
  height: 56px;
  width: calc(50% - 8px) !important;
}

input[type="checkbox"] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.check-cont {
  display: flex;
  width: 50%;
}

.checkbox-root {
  width: 24px;
  height: 24px;
  background-color: var(--white);
  border: 1.5px solid var(--darkgreen);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--blackA7);
  padding: 0px;
}

.checkbox-root:hover {
  opacity: 1;
}

.checkbox-indicator {
  color: var(--darkgreen);
}

label {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

select {
  height: 56px;
  width: 100%;
  border: none;
  background: var(--palegreen);
  border-radius: 8px;
  font-family: "Outfit";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 16px 0px 16px;
  appearance: none;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

button {
  height: 56px;
  cursor: pointer;
  background: var(--darkgreen);
  border-width: 2px;
  border-style: solid;
  border-color: var(--darkgreen);
  border-radius: 8px;
  padding: 0px 16px 0px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button.half {
  width: 50%;
}

button.submit-button {
  width: 100%;
}

button:hover,
button:disabled {
  opacity: 0.8;
}

button:hover,
button:disabled {
  opacity: 0.8;
}

button.pill {
  height: fit-content;
  width: auto;
  cursor: pointer;
  background: var(--darkgreen);
  color: var(--white);
  border-radius: 16px;
  border: 2px solid var(--darkgreen);
  padding: 6px 14px 6px 14px;
  margin-bottom: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

button.pill:not(.secondary):hover {
  opacity: 0.7;
}

button.pill.large {
  background-color: var(--darkgreen);
  border-radius: 20px;
  padding: 8px 20px 8px 20px;
  margin-bottom: 0px;
}

button.pill.x-large {
  background-color: var(--darkgreen);
  border-radius: 50px;
  padding: 16px 28px 16px 28px;
  margin-bottom: 0px;
}

button.secondary {
  border: 1px solid var(--darkgreen);
  background-color: transparent;
  color: var(--darkgreen);
}

button.warning {
  background-color: rgb(223, 141, 8);
  border: 1px solid rgb(223, 141, 8);
  color: var(--white);
}

button.danger {
  background-color: var(--darkred);
  color: var(--white);
}

button.secondary:hover {
  opacity: 0.5;
  /* background-color: var(--white); */
}

button.pill.right {
  margin-left: auto;
}

.button-cont {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  column-gap: 8px;
}

.button-cont.row {
  flex-direction: row;
}

.selectable-button {
  height: 56px;
  width: calc(50% - 8px);
  border: 1px solid var(--black20);
  color: var(--black);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.password-icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 16px;
}

.selectable-button.selected {
  background-color: var(--palegreen);
  border: 1px solid var(--darkgreen);
}

.button-txt {
  color: var(--white);
  cursor: pointer;
}

.title-txt {
  color: var(--darkgreen);
  font-weight: 700;
}

.panel-title-txt {
  color: var(--darkgreen);
  font-weight: 500;
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  row-gap: 16px;
}

.icon-text-cont {
  display: flex;
  column-gap: 8px;
  align-items: center;
  height: 18px;
}

.green-text-cont {
  width: 100%;
  background: var(--palegreen);
  color: var(--darkgreen);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.green-text-cont.clickable {
  cursor: pointer;
}

.green-text-cont.break {
  word-wrap: break-all;
  word-break: break-all;
}

.green-text-cont.left {
  flex-direction: column;
  align-items: first baseline;
  justify-content: left;
  text-align: left;
  row-gap: 8px;
}

.red-text-cont {
  width: 100%;
  border: 1px solid var(--darkred);
  color: var(--darkred) !important;
  border-radius: 8px;
  padding: 16px;
  row-gap: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.red-text-cont.break {
  word-wrap: break-all;
  word-break: break-all;
}

.red-text-cont.left {
  flex-direction: column;
  align-items: first baseline;
  justify-content: left;
  text-align: left;
  row-gap: 8px;
}

.loading-page-container {
  z-index: 998;
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  top: 0;
  left: 0;
}

.loading-element-container {
  z-index: 998;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  background-color: var(--black20);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 999;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.modal-cont {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  z-index: 1000;
  overflow-y: scroll;
  outline: none;
}

.modal-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
  column-gap: 12px;
  align-items: center;
}

.modal-title-subtitle-cont {
  width: 80%;
  flex-direction: column;
  row-gap: 4px;
}

.modal-body {
  display: flex;
  align-items: left;
  text-align: left;
  justify-content: baseline;
  flex-direction: column;
  row-gap: 16px;
  position: relative;
}

.modal-loading-element {
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 50;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-section {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  background-color: var(--palegreen);
  width: calc(100%);
  margin: -24px;
  margin-top: 0px;
  padding: 24px;
  row-gap: 16px;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 0.8;
    transform: translate(-50%, -50%) scale(1);
  }
}

.page-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100vh;
}

.center-page-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 75px;
  row-gap: 32px;
}

.full-page-element {
  max-width: 1350px;
  min-width: 1125px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
}

.full-page-element.row {
  flex-direction: row;
}

.content-container {
  width: 450px;
  align-content: space-between;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.column-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 16px;
}

.half-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: calc(50% - 8px);
  row-gap: 8px;
}

.blur_div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 10;
  text-align: center;
}

.module-container {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  width: 100%;
}

.onethird-full-height-module {
  position: relative;
  padding: 24px;
  padding-top: 0px;
  width: calc(33% - 8px);
  height: calc(100vh - 275px);
  min-height: 400px;
  border: 1px solid var(--black20);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.twothird-full-height-module {
  position: relative;
  padding: 24px;
  padding-top: 0px;
  width: calc(67% - 8px);
  height: calc(100vh - 275px);
  min-height: 400px;
  border: 1px solid var(--black20);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.twofifth-full-height-module {
  position: relative;
  padding: 24px;
  padding-top: 0px;
  width: calc(40% - 8px);
  height: calc(100vh - 275px);
  min-height: 400px;
  border: 1px solid var(--black20);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.threefifth-full-height-module {
  position: relative;
  padding: 24px;
  padding-top: 0px;
  width: calc(60% - 8px);
  height: calc(100vh - 275px);
  min-height: 400px;
  border: 1px solid var(--black20);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.threefifth-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100;
  width: calc(60% - 8px);
  height: calc(100vh - 250px);
  min-height: 424px;
  row-gap: 14px;
}

.half-height-module {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px;
  padding-top: 0px;
  padding-bottom: 16px;
  width: calc(100% - 48px);
  flex: 1;
  border: 1px solid var(--black20);
  border-radius: 8px;
  row-gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.module-header {
  position: sticky;
  top: 0;
  width: calc(100%);
  backdrop-filter: blur(5px);
  margin-top: -16px;
  margin-left: -16px;
  margin-right: -16px;
  padding: 16px;
  padding-top: 24px;
  row-gap: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.module-component {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid var(--black20);
  row-gap: 8px;
  width: auto;
  display: flex;
  flex-direction: column;
}

.module-component-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.accordion-item {
  width: 100%;
  border: var(--palegreen) 2px solid;
  border-radius: 8px;
  box-sizing: border-box;
}

.accordion-trigger {
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
}

.accordion-chevron {
  transition: transform 500ms;
  margin: auto 0px;
}

.accordion-content {
  overflow: hidden;
  box-sizing: border-box;
  padding: 16px;
  padding-top: 0px;
}

.accordion-content[data-state="open"] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.accordion-content[data-state="closed"] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.accordion-trigger[data-state="open"] > .accordion-chevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.user-card-container > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

input.with-icon {
  padding-left: 40px;
}

.input-search-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--black20);
}

.app-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 8px;
  border-radius: 8px;
  width: auto;
  height: fit-content;
  background-color: var(--palegreen);
  height: 43px;
  min-width: 120px;
}

.app-card.clickable {
  cursor: pointer;
}

.user-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 8px;
  border-radius: 8px;
  width: auto;
  height: fit-content;
  background-color: var(--palegreen);
  min-width: 150px;
  cursor: pointer;
}

.card-header {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}

.user-profile-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  background-color: var(--darkgreen);
  height: 32px;
  width: 32px;
  border-radius: 32px;
  color: var(--white);
}

.navbar-cont {
  padding: 24px;
  padding-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.demo-banner {
  background-color: var(--darkgreen);
  color: var(--white);
  padding: 8px;
  display: flex;
  position: fixed;
  width: 100vw;
  /* backdrop-filter: blur(5px); */
  font-family: "Outfit";
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: inset 0px -11px 8px -10px #62696281;
}

.navbar-buttons {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.form-cont {
  width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.form-cont.wide {
  width: 450px;
}

.form-cont.fill {
  width: 100%;
}

.form-cont.left {
  text-align: left;
}

.half-input-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-error-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-txt {
  color: red;
  text-align: center;
}

.add-apps-container {
  height: 168px;
  width: 100%;
  border: 1px solid var(--darkgreen);
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 8px;
  column-gap: 8px;
  row-gap: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  flex-wrap: wrap;
  overflow-y: auto;
}

.add-apps-container.empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-apps-container {
  height: 168px;
  width: 100%;
  border: 1px solid var(--darkred);
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 8px;
  column-gap: 8px;
  row-gap: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  flex-wrap: wrap;
  overflow-y: auto;
}

.remove-apps-container.empty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-apps-container::-webkit-scrollbar,
.remove-apps-container::-webkit-scrollbar {
  width: 8px;
}

.add-apps-container::-webkit-scrollbar-thumb,
.remove-apps-container::-webkit-scrollbar-thumb {
  background-color: hsl(206 22% 7% / 20%);
  border-radius: 10px;
}

.edit-app-tag {
  height: 56px;
  background: var(--palegreen);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  line-height: 24px;
  padding: 0px 16px 0px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0px;
  cursor: pointer;
}

.edit-app-tag.remove {
  height: 56px;
  background: var(--palered);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  line-height: 24px;
  padding: 0px 16px 0px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0px;
}

.dropdown-container {
  background-color: var(--palegreen);
  border-radius: 8px;
  margin-bottom: 16px;
  z-index: 1;
}

.dropdown-search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdown-search {
  border-radius: 0px;
  margin-bottom: 0px;
  border-radius: 8px;
  width: calc(100% - 50px);
  background-color: none;
}

.dropdown-items-container {
  background-color: var(--palegreen);
  overflow: hidden;
  max-height: 120px;
  width: 450px;
  overflow-y: scroll;
  position: absolute;
  border-radius: 8px;
  margin-top: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  padding-left: 16px;
  padding-right: 16px;
}

.app-icon-button-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  row-gap: 8px;
  border-radius: 8px;
}

.app-icon-button-cont:hover {
  background-color: var(--palegreen);
}

.app-icon-button-cont.selected {
  border: 1px solid var(--darkgreen);
  margin: -1px;
}

.app-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.app-icon.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--darkgreen);
  font-weight: 700;
  font-size: 18px;
  color: var(--white);
}

.app-icon.small {
  width: 22px;
  height: 22px;
  border-radius: 4px;
}

.app-icon-button-grid-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  margin-top: 8px;
}

.split-input-bg {
  width: 50px;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  color: var(--darkgreen);
  font-size: 24px;
  box-sizing: border-box;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--palegreen);
  border: 1px solid var(--palegreen);
  border-radius: 8px;
}

.split-input-focussed {
  border: var(--black20) 1px solid;
}
